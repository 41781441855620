<script type="text/ecmascript-6">
import charge from "./charge";
import { channelList } from '../utils/content.js';
import '../utils/charge.css'; // 引入样式文件

export default {
  name: 'ChannelPage',
  extends: charge,
  components: {},
  data() {
    return {
      advList: channelList
    }
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  },
}
</script>
    